.loading,
.no-data {
  color: rgba(29, 79, 145, 0.8);
  text-align: center;
}

.await-data {
  text-align: center;
}

.data-block-two {
  margin: 10px;
  background-color: rgba(48, 64, 100, 1);
  padding: 10px;
}

.playlistreport-body {
  margin-left: 10vw;
  margin-right: 10vw;
}

@media (min-width: 1700px) {
  .playlistreport-body {
    margin-left: 15vw;
    margin-right: 15vw;
  }
}

@media (max-width: 1300px) {
  .playlistreport-body {
    margin-left: 10vw;
    margin-right: 10vw;
  }
}

@media (max-width: 920px) {
  .playlistreport-body {
    margin-left: 5vw;
    margin-right: 5vw;
  }
}

@media (max-width: 760px) {
  .playlistreport-body {
    margin-left: 2vw;
    margin-right: 2vw;
  }
}
