.drag-and-drop {
  font-family: 'Exo 2', sans-serif;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;    
}

.drag-and-drop-label,
.drag-and-drop {
  margin-top: 10px;
}

.drag-and-drop-label {
  font-weight: 500;
}

label {
  width: 350px;
  box-sizing: border-box;
}

.drop-green {
  color: green;
}

.drop-grey {
  color: grey;
}